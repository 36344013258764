import React from "react";

import { withRouter } from "react-router";
import "./home.scss";
import { useAuth } from "../../context/ProvideAuth";

const Home = () => {
  let auth = useAuth();

  return (
    <div className="page home">
      <header className="page__header">
        <h1>
          {"Hi"} {auth.currentUser.name.first},{" "}
          {"Welcome to the UL Admin"}
        </h1>
      </header>
      <section className="page__body dashboard-content">

      </section>
    </div>
  );
};

export default withRouter(Home);
