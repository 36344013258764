import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin: auto;
  border-radius: 15px;
`;

export const ErrorContainer = styled.div``;

export const Base = styled.form``;

export const Title = styled.h1``;

export const SubTitle = styled.h3``;

export const Text = styled.p``;

export const Input = styled.input`
  border-radius: var(--border-radius-md);
  border: 0;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  font-family: inherit;
  font-size: 1rem;
  padding: 0.75rem var(--space-sm);
  color: var(--text-default);

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--text-default);
    opacity: 0.6;
  }
`;

export const TextArea = styled.textarea`
  border-radius: var(--border-radius-md);
  border: 0;
  // height: 50px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  font-family: inherit;
  font-size: 1rem;
  padding: 0.75rem var(--space-sm);
  resize: vertical;

  &:focus {
    box-shadow: 0 0 0 2px var(--primary-light) inset;
    outline: none;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  > * + * {
    margin-top: var(--space-sm);
    margin-left: 0;
  }

  &.connected {
    > * + * {
      margin-left: 0;
      margin-top: 0;
      border-top: 1px solid var(--ui-gray-dark);
    }

    > *:not(:last-of-type) {
      border-bottom: 0; 
    }

    > *:not(:first-child):not(:last-child) {
      border-radius: 0;
      // border-top: 0;
      // border-bottom: 0;
    }
  }

  @media screen and (min-width: 576px) {
    flex-direction: row;
    > * + * {
      margin-left: var(--space-md);
      margin-top: 0;
    }
    &.connected {

      > * + * {
        border-left: 1px solid var(--ui-gray-dark);
      }

      > *:not(:last-of-type) {
        border-right: 0; 
        border-bottom: 1px solid var(--ui-gray-dark); 
      }

      > *:last-of-type {
        // border-right: 1px solid var(--ui-gray-dark);
      }
  
      > *:not(:first-child):not(:last-child) {
        border-top: 1px solid var(--ui-gray-dark);
        border-bottom: 1px solid var(--ui-gray-dark);
      }
    }
  }
  
`;

export const Label = styled.label`
  font-weight: 700;
  margin-bottom: var(--space-xs);
`;

export const Submit = styled.button`
  cursor: pointer;
  background-color: var(--primary-dark);
  width: 100%;
  position: relative;

  &.loading .submit-text {
    visibility: hidden;
    opacity: 0;
  }
  &.loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
  
    to {
      transform: rotate(1turn);
    }
  }
`;

export const Dropdown = styled.div`
  position: relative;
  width: fit-content;

  select {
    appearance: none;
    background-color: var(--ui-blue);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem 2.5rem 0.6rem 1.25rem;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    color: var(--text-default);
    cursor: pointer;
    font-size: 1.125rem;
    outline: none;
    border-radius: var(--border-radius-md);
    transition: all 0.2s;
    text-align: center;
    line-height: 1.5;
    border: 1px solid var(--text-default);
  }

  svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    margin-top: 1px;
  }
`;

export const MobileWizard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
  
  &.mobile-wizard--top {
   
  }

  &.mobile-wizard--bottom {
    margin-bottom: 0;
    @media screen and (min-width: 576px) {
      display: none;
    }
  }

  button {
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
