import React, { createContext, useContext, useState } from "react";
import APIService from "../services/api-service";
//create the context
const authContext = createContext();
//create the provider function component that wraps the app
const ProvideAuth = (props) => (
  <authContext.Provider value={useProvideAuth()}>
    {props.children}
  </authContext.Provider>
);
//create the public useAuth hook/
export const useAuth = () => useContext(authContext);

// create the useProvideAuth provider hook that is used by the public useAuth hook
const useProvideAuth = () => {
  const [currentUser, setCurrentUser] = useState();
  const login = async (email, password) => {
    // check localStorage first;
    await APIService.login(email, password).then(
      (result) => {
        APIService.token = result.bearer;
        setCurrentUser(result);
        localStorage.setItem("ul-cta-user", JSON.stringify(result));
      },
      () => {
        localStorage.removeItem("ul-cta-user");
      }
    );

    return;
  };

  const logout = () => {
    setCurrentUser(undefined);
    localStorage.removeItem("ul-cta-user");
    APIService.token = null;
    return;
  };

  return {
    login,
    logout,
    currentUser,
    setCurrentUser,
  };
};

export default ProvideAuth;
