import React from "react";
import { withRouter } from "react-router-dom";
import Sidebar from "../sidebar/";
import Footer from "../footer/";
import "./layout.scss";
// import NotificationList from "../notifications";

const Layout = ({ children }) => {
  const isStaging = !!process.env.IS_STAGING;
  return (
    <div className="layout">
      <Sidebar />
      {children}
      <Footer />
      {/* <NotificationList /> */}
      {isStaging && (
        <div className="staging-tag">Staging</div>
      )}
    </div>
  );
};

export default withRouter(Layout);
