import React, { useReducer } from "react";
//import APIService from '../../services/api-service';
import HostContext from "./host-context";
import hostReducer from "./host-reducer";
import {
  CLEAR_CURRENT,
  GET_HOSTS,
  SET_CURRENT,
  ADD_HOST,
  HOST_ERROR,
  UPDATE_HOST,
} from "../types";

import APIService from "../../services/api-service";

const HostState = (props) => {
  const initialState = {
    hosts: [],
    current: null,
    error: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(hostReducer, initialState);

  // Get Hosts
  const getHosts = async () => {
    APIService.getPreferredHosts().then(
      (result) => {
        dispatch({ type: GET_HOSTS, payload: result.hosts });
      },
      () => {
        dispatch({ type: HOST_ERROR });
      }
    );
  };

  // Add Host
  const addHost = async (host) => {
    return new Promise((resolve, reject) => {
      APIService.createHost(host).then((result) => {
        dispatch({
          type: ADD_HOST,
          payload: result,
        });
        resolve(result.uuid);
      });
    });
  };

  // Update Host
  const updateHost = async (uuid, host) => {
    APIService.updateHost(uuid, host).then((updatedData) => {
      dispatch({
        type: UPDATE_HOST,
        payload: updatedData,
      });
    });
  };

  // set current host
  const setCurrent = (uuid) => {
    APIService.getHost(uuid).then(
      (result) => {
        dispatch({ type: SET_CURRENT, payload: result });
      },
      () => {
        dispatch({ type: HOST_ERROR });
      }
    );
  };

  // clear current host
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  return (
    <HostContext.Provider
      value={{
        hosts: state.hosts,
        loading: state.loading,
        current: state.current,
        getHosts,
        addHost,
        updateHost,
        setCurrent,
        clearCurrent,
      }}
    >
      {props.children}
    </HostContext.Provider>
  );
};

export default HostState;
