// eslint-disable-next-line no-unused-vars
import { useContext, useEffect, useRef, RefObject } from "react";
import conversationContext from "../../context/conversations/conversation-context";
import { useAuth } from "../../context/ProvideAuth";
import { useNotifications } from "../../context/ProvideNotifications";
import APIService from "../../services/api-service";
const Socket = () => {
  const notifications = useNotifications();
  const auth = useAuth();
  const { newSocketMessage, currentConversation, currentConversationUUID } =
    useContext(conversationContext);
  const WEBSOCKET_API = "ws://localhost:80/api/v1/connect-team";
  const handlers = {
    newSocketMessage,
    unresponsiveHost: (message) => {},
  };
  /**
   * @type {RefObject<WebSocket>}
   */
  const socket = useRef(null);
  useEffect(() => {
    let cancelled = false;
    if (auth.currentUser && !cancelled) {
      connect(socket, handlers);
    }
    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth.currentUser,
    currentConversation,
    currentConversationUUID,
    newSocketMessage,
  ]);

  const [maxDuration, growthFactor] = [10000, 1.3];
  let currentDuration = 200;
  const reconnectDelay = () => {
    currentDuration = Math.pow(currentDuration, growthFactor);
    currentDuration =
      currentDuration > maxDuration ? maxDuration : currentDuration;
    return currentDuration;
  };
  /**
   * @param {RefObject<WebSocket>} ref
   */
  const connect = (ref, { newSocketMessage, unresponsiveHost }) => {
    const handleMessage = (e) => {
      const message = JSON.parse(e.data);
      switch (message.event) {
        case "new-message":
          newMessageHandler(message);
          break;
        case "unresponsive-host":
          unresponsiveHostHandler(message);
          break; 
        case "new-seeker-in-alternate-view":
          newSeekerInAlternateViewHandler(message)
          break;
        default:
          break;
      }
    };
    const newMessageHandler = (message) => {
      newSocketMessage(message);
      // insert into conversation context/ update most recent message
      const name = message.data.seeker_name;
      let displayName = name?.first ? name?.first : name?.last;
      notifications.pushNotification(`New message from ${displayName}`);
    };
    const unresponsiveHostHandler = (message) => {
      let notificationText= '1 New Unresponsive host'
      if (message.data.count >1){
        notificationText = `${message.data.count} New Unresponsive Hosts`
        
      }
      notifications.pushNotification(notificationText)
    };
    const newSeekerInAlternateViewHandler = (message)=>{
      const notificationText = `Seeker added to alternate view: ${message.data.fields}`
      notifications.pushNotification(notificationText)
    }

    let socket = ref.current;
    if (ref.current?.readyState !== 1 && ref.current?.readyState !== 0) {
      socket = new WebSocket(WEBSOCKET_API);
    }
    socket.onopen = (event) => {
      socket.send(JSON.stringify({ token: APIService.token }));
    };
    socket.onmessage = (e) => {
      handleMessage(e);
    };
    socket.onclose = (e) => {
      console.log(e);
      setTimeout(() => {
        connect(ref,handlers);
      }, reconnectDelay());
    };
    socket.onerror = () => {
      socket.close();
    };
    ref.current = socket;
    return;
  };

  return null;
};

export default Socket;
