import React, { useReducer } from "react";
import SeekerContext from "./seeker-context";
import seekerReducer from "./seeker-reducer";

import APIService from "../../services/api-service";

const SeekerState = (props) => {
  const initialState = {
    seekers: [],
    current: null,
    error: null,
    loading: false,
    currentMessages: null,
  };

  const [state, dispatch] = useReducer(seekerReducer, initialState);

  const getSeeker = async (sfUserID) => {
    return APIService.getSeekerAndTestimony(sfUserID);
  };

  const getSeekersWithTestimonies = async () => {
    return APIService.getSeekersWithTestimonies();
  };

  const updateSeekerTestimony = async (sfUserID, data) => {
    return APIService.updateSeekerTestimony(sfUserID, data);
  };

  const uploadSeekerProfilePic = async (sfUserID, formData) => {
    return APIService.uploadSeekerProfilePic(sfUserID, formData);
  };

  // // Get Seekers
  // const getSeekers = async (searchTerm = "") => {
  //   if (state.seekers.length === 0 && !state.loading) {
  //     dispatch({ type: GET_SEEKERS_START });
  //     APIService.getPreferredSeekers(searchTerm).then(
  //       (result) => {
  //         dispatch({
  //           type: GET_SEEKERS,
  //           payload: result.seekers.map((seeker) => ({
  //             ...seeker,
  //             name: `${seeker.first_name ?? ""} ${seeker.last_name ?? ""}`,
  //             hook_data: {},
  //             impact_metrics: seeker.impact_metrics[0],
  //           })),
  //         });
  //       },
  //       () => {
  //         dispatch({ type: SEEKER_ERROR });
  //       }
  //     );
  //   }
  // };

  // // Add Seeker
  // const addSeeker = async (seeker) => {
  //   return new Promise((resolve, reject) => {
  //     APIService.createSeeker(seeker).then((result) => {
  //       dispatch({
  //         type: ADD_SEEKER,
  //         payload: result,
  //       });
  //       resolve(result.uuid);
  //     });
  //   });
  // };

  // // Update Seeker
  // const updateSeeker = (uuid, data, updateData) => {
  //   APIService.updateSeeker(uuid, data).then(() => {
  //     dispatch({
  //       type: UPDATE_SEEKER,
  //       payload: updateData,
  //     });
  //   });
  // };

  // // Set current Seeker
  // const setCurrent = async (uuid) => {
  //   APIService.getSeeker(uuid).then(
  //     (result) => {
  //       dispatch({
  //         type: SET_CURRENT,
  //         payload: {
  //           ...result,
  //           name: `${result.first_name ?? ""} ${result.last_name ?? ""}`,
  //         },
  //       });
  //     },
  //     () => {
  //       dispatch({ type: SEEKER_ERROR });
  //     }
  //   );
  // };

  // // Sync current Seeker
  // const syncCurrentSeeker = async (seeker) => {
  //   dispatch({
  //     type: SET_CURRENT,
  //     payload: {
  //       ...seeker,
  //       name: `${seeker.first_name ?? ""} ${seeker.last_name ?? ""}`,
  //     },
  //   });
  // };

  // const getSeekersAndSetCurrent = async (uuid) => {
  //   APIService.getPreferredSeekers().then(
  //     (result) => {
  //       dispatch({
  //         type: GET_SEEKERS,
  //         payload: result.seekers.map((seeker) => ({
  //           ...seeker,
  //           name: `${seeker.first_name ?? ""} ${seeker.last_name ?? ""}`,
  //         })),
  //       });
  //       APIService.getSeeker(uuid).then(
  //         (result) => {
  //           dispatch({
  //             type: SET_CURRENT,
  //             payload: {
  //               ...result,
  //               name: `${result.first_name ?? ""} ${result.last_name ?? ""}`,
  //             },
  //           });
  //         },
  //         () => {
  //           dispatch({ type: SEEKER_ERROR });
  //         }
  //       );
  //     },
  //     () => {
  //       dispatch({ type: SEEKER_ERROR });
  //     }
  //   );
  // };

  // const addCall = async (call) => {
  //   return new Promise((resolve, reject) => {
  //     APIService.createCall(call).then(
  //       (response) => {
  //         let seeker = state.seekers.find(
  //           (s) => s.uuid === response.seeker_uuid
  //         );
  //         if (seeker.phone_calls) {
  //           seeker.phone_calls.push(response);
  //         } else {
  //           seeker.phone_calls = [response];
  //         }
  //         dispatch({ type: UPDATE_SEEKER, payload: seeker });
  //         resolve(true);
  //       },
  //       () => {
  //         dispatch({ type: SEEKER_ERROR });
  //         reject();
  //       }
  //     );
  //   });
  // };

  // const connectSeekerToHost = async (seekerID, hostID) => {
  //   return new Promise((resolve, reject) => {
  //     APIService.createConnection(seekerID, hostID).then((response) => {
  //       dispatch({ type: DELETE_SEEKER, payload: seekerID });
  //       resolve(response);
  //     });
  //   });
  // };

  // const getCurrentMessages = (id) => {
  //   const data = messages[id];
  //   dispatch({ type: GET_CURRENT_MESSAGES, payload: data });
  // };

  // // Clear Current Seeker
  // const clearCurrent = () => {
  //   dispatch({ type: CLEAR_CURRENT });
  // };

  return (
    <SeekerContext.Provider
      value={{
        seekers: state.seekers,
        loading: state.loading,
        current: state.current,
        currentMessages: state.currentMessages,
        getSeeker,
        getSeekersWithTestimonies,
        updateSeekerTestimony,
        uploadSeekerProfilePic
      }}
    >
      {props.children}
    </SeekerContext.Provider>
  );
};

export default SeekerState;
