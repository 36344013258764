import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/ProvideAuth";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../media/logo-ul-black.png";
import "./sidebar.scss";

const Sidebar = ({ location }) => {
  const { pathname } = location;
  let auth = useAuth();
  const { t } = useTranslation();

  const links = [
    {
      name: "Home",
      path: "/",
      icon: faHome,
    },
    {
      name: "Testimony Form",
      path: "/testimony-form",
      icon: faUser,
    },
  ];

  const renderLinks = (arrayOfLinks) => {
    return arrayOfLinks.map(({ name, path, icon }, i) => {
      let active =
        path.length > 1 ? pathname.includes(path) : pathname === path;

      return (
        <Link key={i} to={path}>
          <li className={`page-link-item btn ${active ? "btn-primary" : ""}`}>
            <FontAwesomeIcon icon={icon} />
            <p to={path} className="text-sm-md bold page-link">
              {name}
            </p>
          </li>
        </Link>
      );
    });
  };

  const [isActive, setActive] = useState(false);
  const [isDark, setIsDark] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    document
      .getElementById("root")
      .setAttribute("data-theme", currentTheme ?? "light");

    if (currentTheme === "dark") {
      setIsDark(true);
    }
  }, []);

  function switchTheme(e) {
    if (e.target.checked) {
      document.getElementById("root").setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "dark");
      setIsDark(true);
    } else {
      document.getElementById("root").setAttribute("data-theme", "light");
      localStorage.setItem("theme", "light");
      setIsDark(false);
    }
  }

  function handleLogOut() {
    auth.logout();
  }

  return (
    <nav className="sidebar">
      <div className="sidebar-wrapper">
        <div className="logo">
          <img src={logo} alt="Unite Life Logo" />
        </div>

        <div className="page-links">
          <div
            onClick={handleToggle}
            className={isActive ? "menu-icon active" : "menu-icon"}
          >
            <div className="menu-icon__bar menu-icon__bar--1"></div>
            <div className="menu-icon__bar menu-icon__bar--2"></div>
            <div className="menu-icon__bar menu-icon__bar--3"></div>
          </div>
          <ul className={isActive ? "active" : null}>
            {renderLinks(links)}

            <div className="theme-switch-wrapper">
              <span>{t("sidebar.dark mode")}</span>
              <label className="theme-switch" htmlFor="checkbox">
                <input
                  onChange={switchTheme}
                  checked={isDark}
                  type="checkbox"
                  id="checkbox"
                  aria-label="Switch visual theme"
                />
                <div className="slider round"></div>
              </label>
            </div>
            <div className="logout-link">
              <button onClick={handleLogOut}>{t("sidebar.log out")}</button>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Sidebar);
