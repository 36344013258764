import styled from "styled-components";
import { Link } from "react-router-dom";
import "../../../styles/_config.scss";

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  &.messages {
    .message-container {
      margin-bottom: 1.5rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection === "row" ? "row" : "column"};
  align-items: ${({ flexDirection }) =>
    flexDirection === "row" ? "center" : null};
  width: ${({ width }) => width};

  &.control-group {
    flex-direction: column;
    @media screen and (min-width: 576px) {
      flex-direction: ${({ flexDirection }) =>
        flexDirection === "row" ? "row" : "column"};
    }

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-sm);
`;

export const SubHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    margin-left: var(--space-xs);
    color: var(--secondary-default);
  }
`;

export const Title = styled.h2`
  white-space: nowrap;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 4px;
    margin-left: var(--space-xs);
  }
`;

export const TitleDecoration = styled.div`
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  background-color: var(--table-line);
  opacity: 0.5;
  margin-top: 4px;
  margin-left: var(--space-sm);
`;

export const SubTitle = styled.h3``;

export const Text = styled.p``;

export const Label = styled.label``;

export const ViewLink = styled(Link)`
  color: var(--text-link);
  max-width: 150px;
  text-align: right;
  line-height: 1.4;
`;

export const IconWrapper = styled.div`
  display: inline;
  margin-left: var(--space-xs);
`;

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 300px;
  // margin: var(--space-sm) 0;
  background-color: var(--ui-gray-lighter);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  padding: ${({ padding }) => (padding === "none" ? "0" : "1.5rem")};

  @media screen and (min-width: 576px) and (max-width: 1200px) {
    padding: ${({ padding }) => (padding === "none" ? "0" : "var(--space-md)")};
  }

  @media screen and (min-width: 1200px) {
    padding: ${({ padding }) => (padding === "none" ? "0" : "var(--space-md)")};
  }
`;

export const BodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-md);
  flex-wrap: wrap;

  .control-group + .control-group {
    margin-top: var(--space-sm);
  }

  @media screen and (min-width: 576px) {
    flex-direction: row;
    margin-bottom: var(--space-md);
    .control-group + .control-group {
      margin-top: 0;
    }
  }
`;

export const BodyContent = styled.div`
  > * + * {
    margin-top: var(--space-sm);
    @media screen and (min-width: 1200px) {
      margin-top: 1.5rem;
    }
  }
`;

export const BodyFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  margin-top: auto;
`;

export const InnerCard = styled.div`
  background-color: var(--ui-blue);
  padding: var(--space-md) 1.5rem;
  border-radius: var(--border-radius-md);
  box-shadow: var(--box-shadow-sm);

  @media screen and (min-width: 1200px) {
    padding: var(--space-md);
  }

  &.subgroup {
    padding: 0;
    box-shadow: none;
    @media screen and (min-width: 992px) {
      padding: var(--space-md) 1.5rem;
      box-shadow: var(--box-shadow-sm);
    }
    .subgroup__header {
      padding: var(--space-sm) 1.5rem;
      box-shadow: var(--box-shadow-sm);
      border-top-left-radius: var(--border-radius-md);
      border-top-right-radius: var(--border-radius-md);
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 400;
      font-size: 1rem;
      z-index: 1;
      position: relative;
      margin-bottom: 0;

      @media screen and (min-width: 992px) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 0;
        box-shadow: none;
        padding-bottom: var(--space-sm);
        border-bottom: 1px solid var(--table-line);
      }
    }
    .subgroup__body {
        padding: var(--space-md) 1.5rem;
        background-color: var(--ui-blue-dark);
        box-shadow: var(--box-shadow-sm);
        border-bottom-left-radius: var(--border-radius-md);
        border-bottom-right-radius: var(--border-radius-md);

        @media screen and (min-width: 992px) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          padding: 0;
          margin-top: 1.5rem;
          background-color: var(--ui-blue);
          box-shadow: none;
        }
        
        > .subgroup-item:last-child {
            margin-bottom: 0;
        }
        .subgroup-item {

            &:not(:last-of-type) {
              margin-bottom: 1.5rem;
              border-bottom: 1px solid var(--table-line);
              padding-bottom: var(--space-xs);

              @media screen and (min-width: 1200px) {
                // margin-bottom: 1.5rem;
                border-bottom: none;
                padding-bottom: 0
              }
            }
            .subgroup-item__heading {
                text-transform: uppercase;
                opacity: 60%;
                letter-spacing: 2px;
                margin-bottom: 6px;
            }
            .subgroup-item__content {
                display: block;
                font-weight: bold;
                line-height: 1.5;
            }
            @media screen and (min-width: 576px) and (max-width: 1200px) {
              justify-content: space-between;
              flex-direction: row;
              align-items: flex-end;
              margin-bottom: var(--space-xs);
              width: 100%;

              .subgroup-item__heading {
                // margin-bottom: 0;
                margin-right: var(--space-sm);
              }

              .subgroup-item__content {
                font-size: 1.125rem;
                margin-bottom: 7px;
                text-align: right;
              }
            }

            @media screen and (min-width: 768px) and (max-width: 1200px) {
              .subgroup-item__heading {
                margin-right: var(--space-lg);
              }
              .subgroup-item__content {
                font-size: 1.25rem;
                margin-bottom: 6px;
              }
            }
        }

        .subgroup-group {
          @media screen and (max-width: 576px) {
            display: flex;
            flex-direction: row;
            .subgroup-item {
              &:not(:last-of-type) {
                padding-right: var(--space-sm);
                width: 50%;
              }
            }
          }
          @media screen and (min-width: 1200px) {
            display: flex;
            flex-direction: row;
            .subgroup-item {
              &:not(:last-of-type) {
                padding-right: var(--space-sm);
                width: 50%;
              }
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  cursor: pointer;
  height: 100%;
  object-fit: cover;
  padding: 0;
  margin: 0;
`;

export const Button = styled.button`
  display: inline-block;
  padding: 0.75rem 1.8rem;
  color: var(--text-default);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.5rem;
  outline: none;
  border: none;
  border-radius: 50px;
  transition: all 0.2s ease-in;
  text-align: center;
  box-sizing: border-box;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  margin: calc(-1 * var(--space-md)) 0 0 calc(-1 * var(--space-md));
  width: 100%;
  height: 100%;
  background-color: #ffffff90;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--space-md) 0;
  z-index: 50;
`;
