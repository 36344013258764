import React from "react";
import Spinner from "../spinner/spinner";
import {
  Container,
  Body,
  BodyHeader,
  BodyContent,
  BodyFooter,
  Title,
  TitleDecoration,
  SubTitle,
  Text,
  Heading,
  SubHeading,
  ViewLink,
  IconWrapper,
  InnerCard,
  Label,
  Image,
  Wrapper,
  Button,
  SpinnerWrapper,
} from "./styles/Card";

export default function Card({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Card.Heading = function CardHeading({ children, ...restProps }) {
  return <Heading {...restProps}>{children}</Heading>;
};

Card.SubHeading = function CardSubHeading({ children, ...restProps }) {
  return <SubHeading {...restProps}>{children}</SubHeading>;
};

Card.Title = function CardTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};
Card.TitleDecoration = function CardTitleDecoration({
  children,
  ...restProps
}) {
  return <TitleDecoration {...restProps}>{children}</TitleDecoration>;
};

Card.SubTitle = function CardSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>;
};

Card.Text = function CardText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

Card.Label = function CardLabel({ children, ...restProps }) {
  return <Label {...restProps}>{children}</Label>;
};

Card.ViewLink = function CardLink({ children, ...restProps }) {
  return <ViewLink {...restProps}>{children}</ViewLink>;
};

Card.IconWrapper = function CardIconWrapper({ children, ...restProps }) {
  return <IconWrapper {...restProps}>{children}</IconWrapper>;
};

Card.Body = function CardBody({ children, ...restProps }) {
  return <Body {...restProps}>{children}</Body>;
};

Card.BodyHeader = function CardBodyHeader({ children, ...restProps }) {
  return <BodyHeader {...restProps}>{children}</BodyHeader>;
};

Card.BodyContent = function CardBodyContent({ children, ...restProps }) {
  return <BodyContent {...restProps}>{children}</BodyContent>;
};

Card.BodyFooter = function CardBodyFooter({ children, ...restProps }) {
  return <BodyFooter {...restProps}>{children}</BodyFooter>;
};

Card.InnerCard = function CardInnerCard({ children, ...restProps }) {
  return <InnerCard {...restProps}>{children}</InnerCard>;
};

Card.Image = function CardImage({ children, ...restProps }) {
  return <Image {...restProps}>{children}</Image>;
};

Card.Wrapper = function CardWrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>;
};

Card.Button = function CardButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
};

Card.Spinner = function CardSpinner({ text = "Loading...", ...restProps }) {
  return (
    <SpinnerWrapper {...restProps}>
      <Spinner text={text} />
    </SpinnerWrapper>
  );
};
