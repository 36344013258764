import React from "react";
import "./spinner.scss";

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="dot first"></div>
      <div className="dot second"></div>
      <div className="dot third"></div>
      <div className="dot fourth"></div>
      <div className="line vertical"></div>
      <div className="line horizontal"></div>
      <div className="line diagonal"></div>
    </div>
  );
};

export default Spinner;
