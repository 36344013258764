import React, { useContext, useEffect, useState } from "react";

import SeekerContext from "../../context/seekers/seeker-context";
import { withRouter } from "react-router";
import { useNotifications } from "../../context/ProvideNotifications";
import NotificationList from "../../components/notifications";
import Form from "../../components/form";
import Select from "react-select-virtualized";
import "./seeker-testimony-form.scss";

const SeekerTestimonyForm = () => {
  const {
    getSeeker,
    getSeekersWithTestimonies,
    updateSeekerTestimony,
    uploadSeekerProfilePic
  } = useContext(SeekerContext);

  const [seekersForSelect, setSeekersForSelect] = useState([]);
  const [selectedSeeker, setSelectedSeeker] = useState({});
  const [seekerSFUserId, setSeekerSFUserId] = useState("");

  const [testimonyBody, setTestimonyBody] = useState("");
  const [testimonyVideoId, setTestimonyVideoId] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");

  const [seekerSearchPending, setSeekerSearchPending] = useState(false);
  const [formSubmitPending, setFormSubmitPending] = useState(false);
  const [disableFormSubmit, setDisableFormSubmit] = useState(false);

  const { pushNotification } = useNotifications();

  const SURVEY_TYPES = ["3D", "2W", "2M", "6M", "12M"]

  // Util
  const findTextTestimony = (testimonies) => {
    return testimonies.find(testimony => testimony.testimony_super_type === "html");
  };
  const findVideoTestimony = (testimonies) => {
    return testimonies.find(testimony => testimony.testimony_super_type === "video");
  };

  const handleSeekerSelect = async (selectedSeeker) => {
    setSeekerSFUserId("");
    if (selectedSeeker === null) {
      setSelectedSeeker({});
      setTestimonyBody("");
      setTestimonyVideoId("");
      setPhotoUrl("");
    } else {
      setSelectedSeeker(selectedSeeker);
      setTestimonyBody(findTextTestimony(selectedSeeker?.testimonies)?.body || "");
      setTestimonyVideoId(findVideoTestimony(selectedSeeker?.testimonies)?.body || "");
      setPhotoUrl(findTextTestimony(selectedSeeker?.testimonies)?.profile_photo_url || "");
    }
  };

  const setCurrentSeeker = (seekerCall) => {
    if (seekerCall.error) {
      setSelectedSeeker({});
      setTestimonyBody("");
      setTestimonyVideoId("");
      setPhotoUrl("");
      pushNotification(seekerCall.error);
    } else if (seekerCall) {
      const { seeker, testimonies } = seekerCall;
      setSelectedSeeker({
        first_name: seeker.first_name || '',
        last_name: seeker.last_name || '',
        value: seeker.salesforce_user_id, // sf id needs to be "value" bc <Select> is weird
        label: `${seeker.first_name || ""} ${seeker.last_name || ""} - SF User ID: ${seeker.salesforce_user_id || "Not Found"}`,
        testimonies: testimonies,
        seeker_uuid: seeker.uuid,
      });
      setTestimonyBody(findTextTestimony(testimonies)?.body || "");
      setTestimonyVideoId(findVideoTestimony(testimonies)?.body || "")
      setPhotoUrl(findTextTestimony(testimonies)?.profile_photo_url || "");
    }
  };

  const handleSeekerSearchSubmit = async (e) => {
    e.preventDefault();
    setSeekerSearchPending(true);
    const result = await getSeeker(seekerSFUserId);
    setSeekerSearchPending(false);
    setCurrentSeeker(result);
  }

  const [newProfilePhoto, setNewProfilePhoto] = useState(null);
  const uploadNewProfilePic = async () => {
    try {
      if (!newProfilePhoto) {
        pushNotification("Error: Please select a file first.");
        throw new Error('Select a file first.');
      }
      const formData = new FormData();
      formData.append('file', newProfilePhoto[0]);

      const result = await uploadSeekerProfilePic(selectedSeeker.value, formData);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitPending(true);
    setDisableFormSubmit(true);

    const response = newProfilePhoto && await uploadNewProfilePic();
    const upload = response && await response.json();

    const oldVideoId = findVideoTestimony(selectedSeeker.testimonies)?.body;
    const changeVideoId = oldVideoId !== testimonyVideoId;

    // 1. Update the text testimony
    let updateTextTestimony = await updateSeekerTestimony(
      selectedSeeker.value, // sf_user_id
      {
        testimony_super_type: "html",
        testimony_type: "html",
        body: testimonyBody.replace(/<[^>]*>?/gm, ''),
        profile_photo_url: upload ? upload.url : selectedSeeker?.testimonies[0]?.profile_photo_url || '',
      }
    );
    // 2. Update the video testimony if a video ID exists
    let updateVideoTestimony = changeVideoId && testimonyVideoId.length && await updateSeekerTestimony(
      selectedSeeker.value, // sf_user_id
      {
        testimony_super_type: "video",
        testimony_type: "youtube",
        body: testimonyVideoId,
        profile_photo_url: upload ? upload.url : selectedSeeker?.testimonies[0]?.profile_photo_url || '',
      }
    );

    let allUpdatesSucceeded = true;
    let errorMessage = "";
    const checkIfStepSucceeded = (step) => {
      if (step.error) {
        errorMessage += step.message;
        allUpdatesSucceeded = false;
      }
    }

    if (upload) {
      checkIfStepSucceeded(upload);
    }
    if (updateTextTestimony) {
      checkIfStepSucceeded(updateTextTestimony);
    }
    if (updateVideoTestimony) {
      checkIfStepSucceeded(updateVideoTestimony);
    }

    if (allUpdatesSucceeded) {
      pushNotification("Testimony updated successfully!");
      // refetch seeker
      const result = await getSeeker(selectedSeeker.value);
      setCurrentSeeker(result);
    } else {
      pushNotification(errorMessage);
    }

    // reset some stuff
    setTimeout(() => setDisableFormSubmit(false), 4000);
    setFormSubmitPending(false);
    setNewProfilePhoto(null);
    document.getElementById("file-upload-input").value = "";
    await getSeekers();
  };

  const getSeekers = async () => {
    let { seekersWithTestimonies } = await getSeekersWithTestimonies();
    seekersWithTestimonies = seekersWithTestimonies.map((seeker) => {
      return {
        first_name: seeker.first_name || '',
        last_name: seeker.last_name || '',
        value: seeker.salesforce_user_id, // sf id needs to be "value" bc <Select> is weird
        label: `${seeker.first_name || ""} ${seeker.last_name || ""} - SF User ID: ${seeker.salesforce_user_id || "Not Found"}`,
        testimonies: seeker.testimonies,
        seeker_uuid: seeker.uuid,
      }
    })
    setSeekersForSelect(seekersWithTestimonies);
  };

  useEffect(() => {
    getSeekers();
    // eslint-disable-next-line
  }, []);

  const seekerIsSelected = Object.keys(selectedSeeker).length > 0;

  return (
    <div className="page seeker-testimony-form">
      <header className="page__header">
        <h1>
          Seeker Testimony Form
        </h1>
      </header>
      <section className="page__body seeker-testimony-form-content">
        <Form>
          <Form.Base onSubmit={handleSeekerSearchSubmit}>
            <Form.Row>
              <Form.InputContainer>
                <Form.Label>Select a seeker:</Form.Label>
                <Select
                options={seekersForSelect}
                onChange={handleSeekerSelect}
                ></Select>
                Or
                <Form.Label>Enter a Salesforce User ID:</Form.Label>
                <Form.Input
                  type="text"
                  name="salesforce_user_id"
                  value={seekerSFUserId}
                  onChange={(e) => setSeekerSFUserId(e.target.value)}
                />
                <br/>
                <Form.Submit className={`btn btn-secondary ${seekerSearchPending ? 'loading' : ''}`} value="Search" />
              </Form.InputContainer>
            </Form.Row>
          </Form.Base>
        </Form>
        {seekerIsSelected && (
          <Form>
          <h2>Create/Update Seeker Testimony - {`${selectedSeeker.first_name} ${selectedSeeker.last_name}`}</h2>
          <span>SF User ID: {selectedSeeker.value}</span>
            <Form.Base onSubmit={handleFormSubmit}>
              <Form.Row>
                <Form.InputContainer>
                  <Form.Label>Upload profile photo:</Form.Label>
                  <img className="testimony-profile-pic" alt="profile pic" src={photoUrl} />
                  <span>Change image: <input id="file-upload-input" type="file" accept="image/*" onChange={event => setNewProfilePhoto(event.target.files)} /></span>
                  <Form.Label>Testimony body:</Form.Label>
                  <Form.TextArea
                    type="text"
                    name="testimony-body"
                    value={testimonyBody}
                    onChange={(e) => setTestimonyBody(e.target.value)}
                  />
                  <div className="add-video-instructions">
                    <Form.Label>Instructions to add video:</Form.Label>
                    <ol>
                      <li>Upload the video to YouTube.</li>
                      <li>In the YouTube link, find v=<br></br>
                        example: https://www.youtube.com/watch?<strong>v=6GGFb6LcX3U</strong>&ab_channel=WeAreChristians
                      </li>
                      <li>Copy the Video ID listed after the v= and Paste the Video in the box below.<br></br>
                        example: <strong>6GGFb6LcX3U</strong>
                      </li>
                    </ol>
                  </div>
                  <Form.Label>YouTube Video ID:</Form.Label>
                  {testimonyVideoId && (
                    <span>View: <a href={`https://www.youtube.com/watch?v=${testimonyVideoId}`} target="_blank">https://www.youtube.com/watch?v={testimonyVideoId}</a></span>
                  )}
                  <Form.TextArea
                    type="text"
                    name="testimony-video-id"
                    value={testimonyVideoId}
                    onChange={(e) => setTestimonyVideoId(e.target.value)}
                  />
                  <br/>
                  
                  <div>
                    <Form.Label>Available surveys:</Form.Label>
                      <ul>
                        {SURVEY_TYPES.map(surveyType => (
                          <li key={surveyType}>
                            <strong>{surveyType} Survey: </strong>
                            <a href={`https://d2hhsxmi8c33yv.cloudfront.net/survey/${surveyType}?uuid=${selectedSeeker.seeker_uuid}`} target="_blank" rel="noreferrer">
                              {`https://d2hhsxmi8c33yv.cloudfront.net/survey/${surveyType}?uuid=${selectedSeeker.seeker_uuid}`}
                            </a>
                          </li>
                          )
                        )}
                      </ul>
                  </div>
                  <br/>
                  <Form.Submit className={`btn btn-secondary ${formSubmitPending && 'loading'}`} value="Submit" disabled={disableFormSubmit} />
                </Form.InputContainer>
              </Form.Row>
            </Form.Base>
          </Form>
        )}
      </section>
      <section className="page__subsection">
        <NotificationList />
      </section>
    </div>
  );
};

export default withRouter(SeekerTestimonyForm);
