/* eslint-disable import/no-anonymous-default-export */
import {
  GET_HOSTS,
  SET_CURRENT,
  CLEAR_CURRENT,
  ADD_HOST,
  UPDATE_HOST,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_HOSTS:
      return {
        ...state,
        hosts: action.payload,
        loading: false,
      };
    case ADD_HOST:
      return {
        ...state,
        hosts: [action.payload, ...state.hosts],
        loading: false,
      };
    case UPDATE_HOST:
      return {
        ...state,
        hosts: state.hosts.map((host) =>
          host.id === action.payload.id ? action.payload : host
        ),
        current: action.payload,
        loading: false,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};
