/* eslint-disable import/no-anonymous-default-export */
import {
  GET_SEEKERS,
  SET_CURRENT,
  CLEAR_CURRENT,
  GET_CURRENT_MESSAGES,
  ADD_SEEKER,
  UPDATE_SEEKER,
  DELETE_SEEKER,
  GET_SEEKERS_START,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_SEEKERS:
      return {
        ...state,
        seekers: action.payload,
        loading: false,
      };
    case GET_SEEKERS_START:
      return {
        ...state,
        loading: true,
      };
    case ADD_SEEKER:
      return {
        ...state,
        seekers: [action.payload, ...state.seekers],
        loading: false,
      };
    case UPDATE_SEEKER:
      return {
        ...state,
        seekers: state.seekers.map((seeker) =>
          seeker.uuid === action.payload.uuid ? action.payload : seeker
        ),
        loading: false,
      };
    case DELETE_SEEKER:
      return {
        ...state,
        seekers: state.seekers.filter(
          (seeker) =>
            seeker.uuid !== action.payload &&
            seeker.salesforce_user_id !== action.payload
        ),
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case GET_CURRENT_MESSAGES:
      return {
        ...state,
        currentMessages: action.payload,
      };
    default:
      return state;
  }
};
