import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./Main";
import SeekerState from "./context/seekers/seeker-state";
import HostState from "./context/hosts/host-state";
import "./styles/global.scss";
import ProvideAuth from "./context/ProvideAuth";
import ProvideNotifications from "./context/ProvideNotifications";
import { ConversationState } from "./context/conversations/conversation-state";
import Socket from "./components/socket";

// eslint-disable-next-line
Date.prototype.format = function (withYear = true) {
  let date =
    (this.getMonth() > 8 ? this.getMonth() + 1 : "0" + (this.getMonth() + 1)) +
    "/" +
    (this.getDate() > 9 ? this.getDate() : "0" + this.getDate());

  return withYear ? date + ("/" + this.getFullYear()) : date;
};
//open a socket

const App = () => {
  return (
    <Suspense fallback="loading">
      <ProvideAuth>
        <ProvideNotifications>
          <HostState>
            <SeekerState>
              <ConversationState>
                <Socket />
                <Router>
                  <Main />
                </Router>
              </ConversationState>
            </SeekerState>
          </HostState>
        </ProvideNotifications>
      </ProvideAuth>
    </Suspense>
  );
};

export default App;
