export default class APIService {
  static API =
    process.env.NODE_ENV === "production"
      ? "https://api.pathwayinitiative.com/api/v1"
      : "http://localhost:80/api/v1";
  static AUTH_API =
    process.env.NODE_ENV === "production" || true
      ? "https://api.pathwayinitiative.com/api/v1/auth"
      : "http://localhost:80/api/v1/auth";

  static endpoints = {
    login: "/login",
    seekerTestimony: "/seeker-testimony",
    surveyTypes: "/ul-admin/survey-types"
    // preferredSeekers: "/me/preferred-seekers",/
    // preferredHosts: "/me/preferred-hosts",
    // seeker: "/seekers/",
    // host: "/hosts/",
    // analytics: "/me/analytics",
    // createConnection: "/create-connection",
    // calls: "/calls",
    // metrics: "/impact-metrics/",
    // conversations: "/conversations",
    // myConversations: "/me/conversations",
    // settings: "/me",
    // recentMessages: "/me/recent-messages",
  };

  static token = null;

  static async login(email, password) {
    return new Promise((resolve, reject) => {
      fetch(this.AUTH_API + this.endpoints.login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((result) => {
              resolve(result);
            });
          } else {
            reject();
          }
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

   /**
   * Updates a seeker's testimony or if it has none, creates a testimony
   * @param {String} seekerUUID The UUID of the seeker to look up surveys
   * @returns {Promise<Array>} Array of survey types for that seeker
   */
    static async getSurveyTypesForSeeker(seekerUUID) {
      return this.makeRequest("GET", `${this.endpoints.surveyTypes}/${seekerUUID}`);
    }

  // static async createConnection(seekerID, hostID) {
  //   console.log(seekerID, hostID);
  //   return this.makeRequest("POST", this.endpoints.createConnection, {
  //     seekerID,
  //     hostID,
  //   });
  // }
  /**
   * Gets all preferred seekers for the current user
   * @returns {Array<Object>} All preferred seekers
   */
  // static async getPreferredSeekers(searchTerm = "") {
  //   return this.makeRequest("GET", this.endpoints.preferredSeekers, {
  //     searchTerm: searchTerm,
  //   });
  // }

  /**
   * Gets all preferred hosts for the current user
   * @returns {Array<Object>} All preferred hosts
   */
  // static async getPreferredHosts() {
  //   return this.makeRequest("GET", this.endpoints.preferredHosts);
  // }

  /**
   * Gets a seeker from the API
   * @param {String} hostID The salesforce ID of the seeker to retrieve
   * @returns {Object} The resulting seeker object
   */
  static async getSeeker(seekerID) {
    return this.makeRequest("GET", this.endpoints.seeker + seekerID);
  }

  /**
   * Gets a seeker from the API + their testimony if they have one
   * @param {String} seekerSFID The salesforce ID of the seeker to retrieve
   * @returns {Object} The resulting seeker object and testimony object if it exists
   */
  static async getSeekerAndTestimony(seekerSFID) {
    return this.makeRequest("GET", `${this.endpoints.seekerTestimony}/seeker?sf_user_id=${seekerSFID}`);
  }

  /**
   * Returns a list of seekers that have testimonies along with their testimonies
   * @returns {Object} The resulting object containing an array of seekers
   */
  static async getSeekersWithTestimonies() {
    return this.makeRequest("GET", `${this.endpoints.seekerTestimony}/seekers`);
  }

  /**
   * Updates a seeker's testimony or if it has none, creates a testimony
   * @param {String} seekerSFID The salesforce ID of the seeker to retrieve
   * @param {String} body The form body fields for updating or creating the testimony
   * @returns {Object} A success status
   */
  static async updateSeekerTestimony(seekerSFID, data) {
    return this.makeRequest("PUT", `${this.endpoints.seekerTestimony}/seeker?sf_user_id=${seekerSFID}`, data);
  }

  static async uploadSeekerProfilePic(seekerSFID, formData) {
    return await fetch(`${this.API}${this.endpoints.seekerTestimony}/seeker-photo?sf_user_id=${seekerSFID}`, {
      method: "POST",
      body: formData,
    });
  }

  /**
   * Sync current seeker from api
   * @param {String} seekerID The salesforce ID of the seeker to retrieve
   * @returns {Object} The resulting seeker object
   */
  // static async syncSeeker(seekerID) {
  //   try {
  //     const result = await this.makeRequest(
  //       "GET",
  //       `${this.endpoints.seeker}${seekerID}/sync`
  //     );
  //     return result;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  /**
   * Gets a host from the API
   * @param {String} hostID The salesforce ID of the host to retrieve
   * @returns {Object} The resulting host object
   */
  // static async getHost(hostID) {
  //   return this.makeRequest("GET", this.endpoints.host + hostID);
  // }

  /**
   * Gets analytics data from the API
   * @returns {Object} The resulting analytics data
   */
  // static async getAnalytics(range) {
  //   let result = await this.makeRequest("GET", this.endpoints.analytics, {
  //     range,
  //   });

  //   result = result.map((res) => {
  //     let valueCol = "No data";
  //     if (res.value.length > 0) {
  //       valueCol = Object.keys(res.value[0]).find((x) => x !== "date");
  //     }
  //     let values = res.value.map((value) => ({
  //       date: new Date(value.date).format(false),
  //       [range]: Number.parseFloat(
  //         Number.parseFloat(value[valueCol]).toFixed(2)
  //       ),
  //     }));
  //     return {
  //       title: res.title,
  //       value: values,
  //       keys: [range],
  //     };
  //   });

  //   return result;
  // }

  /**
   * Gets analytics data from multiple date ranges to be displayed in a single table
   * @param {String} firstRange The newer of the two ranges
   * @param {String} secondRange The older of the two ranges
   * @returns {Object} The resulting analytics data
   */
  // static async getMultipleAnalytics(firstRange, secondRange) {
  //   try {
  //     let firstPromise = this.getAnalytics(firstRange);
  //     let secondPromise = this.getAnalytics(secondRange);

  //     let [firstResult, secondResult] = await Promise.all([
  //       firstPromise,
  //       secondPromise,
  //     ]);

  //     let combinedResult = [];
  //     for (let i = 0; i < firstResult.length; i++) {
  //       let valueArray = [];
  //       for (let j = 0; j < firstResult[i].value.length; j++) {
  //         valueArray.push({
  //           [firstRange]: firstResult[i].value[j][firstRange],
  //           [secondRange]: secondResult[i].value[j][secondRange],
  //           date: firstResult[i].value[j].date,
  //         });
  //       }
  //       combinedResult.push({
  //         title: firstResult[i].title,
  //         value: valueArray,
  //         keys: [secondRange, firstRange],
  //       });
  //     }

  //     return combinedResult;
  //   } catch (error) {
  //     console.error(error);
  //     return [];
  //   }
  // }

  /**
   * Creates a seeker in the DB
   * @param {Object} data Seeker data to create
   */
  // static async createSeeker(data) {
  //   return this.makeRequest("POST", this.endpoints.seeker, data);
  // }

  /**
   * Creates a host in the DB
   * @param {Object} data Host data to create
   */
  // static async createHost(data) {
  //   return this.makeRequest("POST", this.endpoints.host, data);
  // }

  /**
   * Creates a seeker in the DB
   * @param {Object} data Seeker data to create
   */
  // static async createCall(data) {
  //   return this.makeRequest("POST", this.endpoints.calls, data);
  // }

  /**
   * Updates an impact metric for a seeker
   * @param {String} uuid The uuid of the seeker
   * @param {String} name The metric to update
   * @param {String} value The value to update it to
   */
  // static async updateImpactMetrics(uuid, metrics) {
  //   return this.makeRequest("PUT", this.endpoints.metrics + uuid, { metrics });
  // }

  // static async updateSeeker(uuid, data) {
  //   return this.makeRequest("PUT", this.endpoints.seeker + uuid, data);
  // }

  // static async updateHost(uuid, data) {
  //   return this.makeRequest("PUT", this.endpoints.host + uuid, data);
  // }

  // static async updateSettings(data) {
  //   return this.makeRequest("PUT", this.endpoints.settings, data);
  // }

  // static async getSettings() {
  //   return this.makeRequest("GET", this.endpoints.settings);
  // }

  /**
   * @param {String} conversation_uuid the uuid of the conversation to send the message in
   * @param {String} message_text the message to send in the given conversation
   */
  // static async createMessage(conversation_uuid, msg_text) {
  //   const path = `${this.endpoints.conversations}/${conversation_uuid}/messages`;
  //   return this.makeRequest("POST", path, { msg_text });
  // }
  // static async createConversation(seeker_uuid, msg_text) {
  //   return this.makeRequest("POST", this.endpoints.conversations, {
  //     seeker_uuid,
  //     msg_text,
  //   });
  // }
  // static async getConversation(conversation_uuid) {
  //   const path = `${this.endpoints.conversations}/${conversation_uuid}`;
  //   return this.makeRequest("GET", path);
  // }
  // static async getConversationMessages(conversation_uuid) {
  //   const path = `${this.endpoints.conversations}/${conversation_uuid}/messages`;
  //   return this.makeRequest("GET", path);
  // }
  // static async getMyConversations() {
  //   return this.makeRequest("GET", this.endpoints.myConversations);
  // }
  // static async getMyRecentMessages(limit = 5) {
  //   const res = await this.makeRequest(
  //     "GET",
  //     this.endpoints.recentMessages,
  //     limit ? { limit: limit } : null
  //   );
  //   console.log(res);
  //   return res;
  // }
  /**
   *
   * @param {String} conversation_uuid the id of the conversation the current message is a part of
   * @param {String} message_uuid the id of the message to update
   * @param {Object} updates the fields on the message to update along with their new values
   * @returns {Promise<Object>} the response from the API
   */
  // static async updateMessage(conversation_uuid, message_uuid, updates) {
  //   const path = `${this.endpoints.conversations}/${conversation_uuid}/messages/${message_uuid}`;
  //   let res = await this.makeRequest("PUT", path, updates);
  //   return res;
  // }

  /**
   * Makes a request to the API
   * @param {String} method The request method
   * @param {String} path The api path (without url)
   * @param {Object} params Parameters to include with the request
   * @returns {Object} The API's response
   */
  static async makeRequest(method, path, params = null) {
    return new Promise((resolve, reject) => {
      if (method === "GET") {
        let endpoint = this.API + path;
        if (params) {
          let queryString = "?";
          Object.keys(params).forEach((key) => {
            queryString += `${key}=${params[key]}&`;
          });
          endpoint = (endpoint + queryString).slice(0, -1);
        }
        fetch(endpoint, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((result) => {
                resolve(result);
              });
            } else {
              reject(response.status);
            }
          })
          .catch(() => null);
      } else {
        fetch(this.API + path, {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((result) => {
                resolve(result);
              });
            } else {
              reject(response.status);
            }
          })
          .catch(() => null);
      }
    });
  }
}
