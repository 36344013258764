import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Layout from "./components/layout/layout";
import Home from "./pages/home";
import SeekerTestimonyForm from "./pages/seeker-testimony-form";
// import Dashboard from "./pages/dashboard";
// import Hosts from "./pages/hosts";
// import Host from "./pages/host";
// import CreateHost from "./pages/create-host";
// import Seekers from "./pages/seekers";
// import Seeker from "./pages/seeker";
// import CreateSeeker from "./pages/create-seeker";
// import Settings from "./pages/settings";
import Login from "./pages/login/login";
import { useAuth } from "./context/ProvideAuth";
// import Chat from "./pages/chat/chat";
import APIService from "./services/api-service";
// import Calls from "./pages/calls/calls";
// import CreateCall from "./pages/create-call/create-call";
// import ImpactMetrics from "./pages/impact-metrics";

const Main = () => {
  let auth = useAuth();

  useEffect(() => {
    if (localStorage.getItem("ul-cta-user")) {
      let result = JSON.parse(localStorage.getItem("ul-cta-user"));
      APIService.token = result.bearer;
      auth.setCurrentUser(result);
    }
    // eslint-disable-next-line
  }, []);

  //Uncomment this line to test auth
  if (!auth.currentUser) {
    return <Login />;
  }
  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/testimony-form" component={SeekerTestimonyForm} />
      </Switch>
    </Layout>
  );
};

export default withRouter(Main);
