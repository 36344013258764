export const GET_SEEKERS = "GET_SEEKERS";
export const GET_SEEKERS_START = "GET_SEEKERS_START";
export const ADD_SEEKER = "ADD_SEEKER";
export const DELETE_SEEKER = "DELETE_SEEKER";
export const UPDATE_SEEKER = "UPDATE_SEEKER";
export const FILTER_SEEKERS = "FILTER_SEEKER";
export const CLEAR_SEEKERS = "CLEAR_SEEKERS";
export const SEEKER_ERROR = "SEEKER_ERROR";
export const HOST_ERROR = "HOST_ERROR";
export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const GET_HOSTS = "GET_HOSTS";
export const ADD_HOST = "ADD_HOST";
export const UPDATE_HOST = "UPDATE_HOST";
export const GET_CURRENT_MESSAGES = "GET_CURRENT_MESSAGES";
