/* eslint-disable import/no-anonymous-default-export */
export const actions = {
  SEND_MESSAGE: "SEND_MESSAGE",
  SEND_FIRST_MESAGE: "SEND_FIRST_MESSAGE",
  GET_MY_CONVERSATIONS: "GET_MY_CONVERSATIONS",
  SET_CURRENT_CONVERSATION_START: "SET_CURRENT_CONVERSATION_START",
  SET_CURRENT_CONVERSATION_END: "SET_CURRENT_CONVERSATION_END",
  CLEAR_CURRENT_CONVERSATION: "CLEAR_CURRENT_CONVERSATION",
  NEW_SOCKET_MESSAGE: "NEW_SOCKET_MESSAGE",
  CURRENT_CONVERSATION_READ: "CURRENT_CONVERSATION_READ",
  CREATE_EPHEMERAL_CONVERSATION: "CREATE_EPHEMERAL_CONVERSATION",
};

export default (state, action) => {
  console.log(action);
  //eslint-disable-next-line
  let conversations, conversationMessages, messages, currentConversation; // for some reason we cant declare these in our switch statement multiple times :(
  switch (action.type) {
    case actions.SET_CURRENT_CONVERSATION_START:
      return {
        ...state,
        loading: true,
        currentConversationUUID: action.payload,
      };
    case actions.SET_CURRENT_CONVERSATION_END:
      //convert array to object using uuid as key
      messages = {};
      for (const message of action.payload.messages) {
        messages[message.uuid] = message;
      }
      //overWrite the record for the current conversation with the version that includes the new messages
      return {
        ...state,
        loading: false,
        conversations: {
          ...state.conversations,
          [action.payload.conversation.uuid]: action.payload.conversation,
        },
        currentConversation: { ...action.payload.conversation, is_read: true },
        conversationMessages: {
          ...state.conversationMessages,
          [action.payload.conversation.uuid]: {
            ...state.conversationMessages[action.payload.conversation.uuid],
            ...messages,
          },
        },
      };
    case actions.CLEAR_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: null,
        currentConversationUUID: null,
      };
    case actions.GET_MY_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload,
        loading: false,
      };
    case actions.SEND_MESSAGE:
      return {
        ...state,
        conversationMessages: {
          ...state.conversationMessages,
          [state.currentConversationUUID]: {
            ...state.conversationMessages[state.currentConversationUUID],
            [action.payload.uuid]: action.payload,
          },
        },
        currentConversation: {
          ...state.currentConversation,
          last_message: {
            last_message_uuid: action.payload.uuid,
            is_read: true,
            msg_created_at: action.payload.created_at,
            msg_text: action.payload.msg_text,
          },
        },
        conversations: {
          ...state.conversations,
          [action.payload.conversation_uuid]: {
            ...state.conversations[action.payload.conversation_uuid],
            last_message: {
              last_message_uuid: action.payload.uuid,
              is_read: true,
              msg_created_at: action.payload.created_at,
              msg_text: action.payload.msg_text,
            },
          },
        },
        loading: false,
      };
    case actions.SEND_FIRST_MESAGE:
      //this is where we will transition from ephemeral conversation to a normal conversation
      //we take in the conversation and message objects we recieved from the server,
      //delete the ephemeral conversation, and set the new conversation as the active one
      const next = {
        ...state,
        conversations: {
          ...state.conversations,
          [action.payload.conversation.uuid]: {
            ...action.payload.conversation,
            last_message: {
              last_message_uuid: action.payload.message.uuid,
              is_read: true,
              msg_created_at: action.payload.message.created_at,
              msg_text: action.payload.message.msg_text,
            },
          },
        },
        conversationMessages: {
          ...state.conversationMessages,
          [action.payload.message.conversation_uuid]: {
            ...state.conversationMessages[
              action.payload.message.conversation_uuid
            ],
            [action.payload.message.uuid]: action.payload.message,
          },
        },
        currentConversation: {
          ...action.payload.conversation,
          last_message: {
            ...action.payload.message,
            last_message_uuid: action.payload.message.uuid,
          },
        },
        currentConversationUUID: action.payload.conversation.uuid,
        redirect: {
          required: true,
          to: action.payload.conversation.uuid,
        },
      };
      delete next.ephemeralConversations[
        action.payload.conversation.seeker_uuid
      ];
      console.log(next);
      return next;
    case actions.NEW_SOCKET_MESSAGE:
      let newMessage = action.payload.data.message;
      const messagePertainsToCurrentConversation =
        state.currentConversationUUID === newMessage.conversation_uuid;

      //if message is read already OR it pertains to the current conversation, we will mark it as read.
      newMessage.is_read =
        newMessage.is_read || messagePertainsToCurrentConversation;

      //copy the conversations into another object
      conversations = {
        ...state.conversations,
        [action.payload.data.message.conversation_uuid]: {
          ...state.conversations[action.payload.data.message.conversation_uuid],
          last_message: newMessage,
        },
      };

      conversationMessages = {
        ...state.conversationMessages,
        [newMessage.conversation_uuid]: {
          ...state.conversationMessages[newMessage.conversation_uuid],
          [newMessage.uuid]: newMessage,
        },
      };

      if (messagePertainsToCurrentConversation) {
        currentConversation = {
          ...state.currentConversation,
          last_message: newMessage,
        };
      } else {
        currentConversation = { ...state.currentConversation };
      }

      // currentConversation = state.currentConversation
      //   ? { ...state.currentConversation }
      //   : null;
      // if (currentConversation) {
      //   currentConversation.last_message = newMessage;
      // }
      return {
        ...state,
        conversations,
        conversationMessages,
        currentConversation,
      };
    case actions.CURRENT_CONVERSATION_READ:
      currentConversation = { ...state.currentConversation };
      currentConversation.last_message.is_read = true;
      conversations = { ...state.conversations };
      conversations[currentConversation.uuid] = currentConversation;
      return {
        ...state,
        conversations,
        currentConversation,
      };
    case actions.CREATE_EPHEMERAL_CONVERSATION:
      // here we will create an ephemeral conversation.
      //  - take in an ephemeral conversation
      //  - update the current conversation to be the newly created ephemeral conversation
      // the ephemeral conversations are keyed with the seeker's uuid, as opposed to normal conversations that are keyed with their own conversation uuid
      return {
        ...state,
        loading: false,
        currentConversation: action.payload,
        ephemeralConversations: {
          ...state.ephemeralConversations,
          [action.payload.seeker.uuid]: action.payload,
        },
      };
    default:
      console.error(
        `you called a reducer action that did not exist: ${action.type}`
      );
      return state;
  }
};
