import React, { useReducer } from "react";
import APIService from "../../services/api-service";
import ConversationContext from "./conversation-context";
import conversationReducer, { actions } from "./conversation-reducer";
export const ConversationState = (props) => {
  const initialState = {
    conversations: {},
    conversationMessages: {},
    currentConversation: null,
    currentConversationUUID: null,
    conversationsLoaded: true,
    loading: true,
    error: false,
    ephemeralConversations: {},
    redirect: {},
  };
  const [state, dispatch] = useReducer(conversationReducer, initialState);
  /**
   * @param {String} msg_text the text of the message to send to the seeker
   */
  const sendMessage = async (msg_text) => {
    let message = await APIService.createMessage(
      state.currentConversationUUID,
      msg_text
    );
    console.log("sendMessage");
    dispatch({ type: actions.SEND_MESSAGE, payload: message });
  };

  const sendFirstMessage = async (msg_text) => {
    console.log("sendFirstMessage");
    let { conversation, message } = await APIService.createConversation(
      state.currentConversationUUID,
      msg_text
    );
    console.log({ conversation, message });
    dispatch({
      type: actions.SEND_FIRST_MESAGE,
      payload: { conversation, message },
    });
  };

  const getMyConversations = async () => {
    console.log("getMyConversations");
    let conversations = (await APIService.getMyConversations()).conversations;
    let conversationsObj = {};
    for (const conversation of conversations) {
      conversationsObj[conversation.uuid] = {
        ...conversation,
        ...conversationsObj[conversation.uuid],
      };
    }

    dispatch({ type: actions.GET_MY_CONVERSATIONS, payload: conversationsObj });
  };
  /**
   *
   * @param {String} id the ID of the conversation to set as `current`
   */
  const setCurrentConversation = async (id) => {
    console.log("setCurrentConversation");
    console.log(id);

    dispatch({
      type: actions.SET_CURRENT_CONVERSATION_START,
      payload: id,
    });
    try {
      let [conversation, messages] = await Promise.all([
        APIService.getConversation(id),
        APIService.getConversationMessages(id),
      ]);
      await markConversationAsRead(conversation);
      dispatch({
        type: actions.SET_CURRENT_CONVERSATION_END,
        payload: { conversation: conversation, messages: messages.messages },
      });
    } catch (e) {
      if (e === 404) {
        //if 404 is returned, there is no conversation. we will now create an ephemeral conversation.
        let seeker = await APIService.getSeeker(id);
        createEphemeralConversation(seeker);
      }
    }
  };
  const clearCurrentConversation = () => {
    console.log("clearCurrentConversation");
    dispatch({
      type: actions.CLEAR_CURRENT_CONVERSATION,
    });
  };

  /**
   * marks the current conversation as read
   */
  const markConversationAsRead = async (conversation) => {
    console.log("markConversatiojnAsRead");
    const shouldMarkRead =
      conversation &&
      conversation.last_message &&
      conversation.last_message.is_read === false;
    if (shouldMarkRead) {
      try {
        await APIService.updateMessage(
          conversation.uuid,
          conversation.last_message.last_message_uuid,
          { is_read: true }
        );
      } catch (e) {}
    }
  };
  /**
   *
   * @param {Object} message websocket message
   */
  const newSocketMessage = async (message) => {
    if (
      message.data.message.conversation_uuid === state.currentConversationUUID
    ) {
      try {
        await APIService.updateMessage(
          state.currentConversationUUID,
          message.data.message.uuid,
          { is_read: true }
        );
      } catch (e) {}
    }
    dispatch({
      type: actions.NEW_SOCKET_MESSAGE,
      payload: message,
    });
  };
  const createEphemeralConversation = async (seeker) => {
    console.log("createEphemeralConversation");
    const ephemeralConversation = {
      ephemeral: true,
      seeker: seeker,
      uuid: seeker.uuid,
      last_message: {
        is_read: true,
        is_from_seeker: true,
        msg_text: "---",
      },
    };
    dispatch({
      type: actions.CREATE_EPHEMERAL_CONVERSATION,
      payload: ephemeralConversation,
    });
  };
  return (
    <ConversationContext.Provider
      value={{
        ...state,
        sendMessage,
        sendFirstMessage,
        getMyConversations,
        setCurrentConversation,
        currentConversation: state.currentConversation,
        clearCurrentConversation,
        newSocketMessage,
      }}
    >
      {props.children}
    </ConversationContext.Provider>
  );
};
