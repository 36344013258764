import React from "react";
import { useTranslation } from "react-i18next";

import "./footer.scss";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer-content">
        <span className="footer-text">{t("footer.powered by")} Unite Life</span>
      </div>
    </div>
  );
};

export default Footer;
