import Card from "../../components/card";
import "./login.scss";
import logo from "../../media/logo-ul-black.png";
import Form from "../../components/form";
import { useState } from "react";
import { useAuth } from "../../context/ProvideAuth";
import { withRouter } from "react-router";

const Login = () => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");

  const auth = useAuth();

  const handleLogin = async () => {
    await auth.login(email, password);
  };

  return (
    <div className="page login">
      <Card>
        <Card.Body className="login-content">
          <div className="logo-wrapper">
            <Card.Image src={logo} alt={"logo"} />
          </div>
          <Form.InputContainer className="input-container">
            <Form.Label>Email</Form.Label>
            <Form.Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="login-email"
              autoFocus
            ></Form.Input>
          </Form.InputContainer>
          <Form.InputContainer className="input-container">
            <Form.Label>Password</Form.Label>
            <Form.Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="login-password"
              type="password"
            ></Form.Input>
          </Form.InputContainer>
          <button
            className="btn btn-primary"
            onClick={handleLogin}
            disabled={email.length === 0 || password.length === 0}
          >
            Log In
          </button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default withRouter(Login);
